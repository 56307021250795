import { useMediaQuery } from "@chakra-ui/media-query"
import React from "react"
import { GetMonths } from "../../global/Months"
export default function Calendar({ months, title }) {
  const monthsArray = GetMonths()

  const [isLargerThan600] = useMediaQuery("(min-width: 600px)")

  const hasTrueValue = Object.values(months).some(val => val === true)

  return (
    <section className={`${!hasTrueValue ? "noSeason" : ""}  calendar`}>
      {monthsArray.map(month => {
        return (
          <div key={month} className="calendarItem">
            <p>
              {isLargerThan600 ? month.slice(0, 3) + "." : month.slice(0, 1)}
            </p>
            {months[month] ? <div className="activeMonth"></div> : ""}
          </div>
        )
      })}
      {!hasTrueValue && (
        <p className="noSeasonText">{title} er en importvare</p>
      )}
    </section>
  )
}
