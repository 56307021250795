import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import PostLayout from "../components/global/PostLayout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"

import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
import SimpleFooter from "../components/global/SimpleFooter"
import Calendar from "../components/pages/ingredients/Calendar"
import AdditionalRecipes from "../components/pages/recipeComponents/AdditionalRecipes"
import { Divider } from "@chakra-ui/layout"
import { blogSchemaMarkup, breadCrumbMarkup } from "../utils/schemas/blogSchema"
export default function ingredient({ data }) {
  const { mainImage, title, _rawBody, months, translation, slug } =
    data.sanityIngredients

  const longerThanZero = data.recipesWithIngredient.edges.length > 0

  const metaTitle =
    "Sæson for " +
    title +
    " | Nem sæsonoversigt for alle ingredienser og råvarer"
  const metaDescription = `Hvornår er ${title} i sæson? Her kan du finde ud af hvornår ${title} også kaldet ${translation.laName} er i sæson.`
  const page = "ingredienser"
  const slugPrefix = "ingrediens"
  const publishedAt = "2021-11-30"
  return (
    <PostLayout showFooter={false}>
      <article className="post ingredient postLayout">
        <SearchEngineOptimizaion
          title={metaTitle}
          description={metaDescription}
          blogMarkup={blogSchemaMarkup(
            metaTitle,
            metaDescription,
            slug,
            mainImage,
            publishedAt,
          )}
          breadCrumbPost={breadCrumbMarkup(slug, page, slugPrefix)}
        />
        <div className="verticalImageContainer">
          <GatsbyImage
            image={mainImage.asset.gatsbyImageData}
            alt={title}
            className="verticalImage "
            loading="lazy"
          />
        </div>
        <div className="rightWrapper hideScrollbar padding">
          <div className="titleWrapper  blogPostTitle ">
            <h1>{title}</h1>
            <div className="spanLineLeft"></div>
          </div>

          {/* Translation */}
          <div className="translation ">
            <h2> En: {translation.enName}</h2>
            <h2> La: {translation.laName}</h2>
          </div>

          <h3 className="calendartitle">Sæson for {title}</h3>
          <Calendar months={months} title={title} />

          <BlockContent
            blocks={_rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="recipeBlockContent"
          />

          <div className="ingredientWithAdditionalRecipes">
            {longerThanZero ? (
              <h4>Opskrifter med {title}</h4>
            ) : (
              <h4>Nyeste opskrifter</h4>
            )}
            <Divider mt="10px" />

            {longerThanZero ? (
              <AdditionalRecipes recipes={data.recipesWithIngredient.edges} />
            ) : (
              <AdditionalRecipes recipes={data.recipesWithNoIngredient.edges} />
            )}
          </div>
          <SimpleFooter soMeLinks={data.sanityCompanyInfo} />
        </div>
      </article>
    </PostLayout>
  )
}

export const query = graphql`
  query ($Slug: String, $Regex: String) {
    sanityIngredients(slug: { current: { eq: $Slug } }) {
      id
      mainImage: image {
        asset {
          gatsbyImageData(
            backgroundColor: "#f9f9f9"
            placeholder: NONE
            width: 350
          )
        }
      }
      title
      slug {
        current
      }
      translation {
        laName
        enName
      }
      months {
        september
        oktober
        november
        marts
        maj
        juni
        januar
        juli
        februar
        december
        august
        april
      }
      category {
        title
        slug {
          current
        }
      }
      _rawBody
    }
    recipesWithIngredient: allSanityRecipes(
      filter: { ingredients: { regex: $Regex } }
      sort: { publishedAt: DESC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          recipeImage {
            verticalImage {
              asset {
                gatsbyImageData(
                  width: 300
                  placeholder: NONE
                  backgroundColor: "#f9f9f9"
                  formats: AVIF
                )
              }
            }
          }
        }
      }
    }
    recipesWithNoIngredient: allSanityRecipes(
      sort: { publishedAt: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          recipeImage {
            verticalImage {
              asset {
                gatsbyImageData(
                  width: 300
                  placeholder: NONE
                  backgroundColor: "#f9f9f9"
                  formats: AVIF
                )
              }
            }
          }
        }
      }
    }
  }
`
